<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                班组工具{{ teamTestInfo.toolName }}基本信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">编号：</div>
                    <div class="info">{{ teamTestInfo.id }}</div>
                </div>
                <div class="item">
                    <div class="label">工器具名称：</div>
                    <div class="info">{{ teamTestInfo.toolName }}</div>
                </div>
                <div class="item">
                    <div class="label">试验类别：</div>
                    <div class="info">{{ teamTestInfo.shTypeName }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">试验地点：</div>
                    <div class="info">{{ teamTestInfo.location }}</div>
                </div>
                <div class="item">
                    <div class="label">试验人员：</div>
                    <div class="info">{{ teamTestInfo.tester }}</div>
                </div>
                <div class="item">
                    <div class="label">试验日期:</div>
                    <div class="info">{{ teamTestInfo.testDate }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">录入员账号：</div>
                    <div class="info">{{ teamTestInfo.account }}</div>
                </div>
                <div class="item">
                    <div class="label">录入时间：</div>
                    <div class="info">{{ teamTestInfo.recordTime }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                班组工具{{ teamTestInfo.toolName }}试验结果
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">是否合格：</div>
                    <div class="info">{{ teamTestInfo.isRegular_text }}</div>
                </div>
                <div class="item">
                    <div class="label">试验报告：</div>
                    <div class="info">
                        <el-link type="primary" :href="path + teamTestInfo.labReport">{{
                            teamTestInfo.labReportName }}</el-link>
                    </div>
                </div>
                <div class="item">
                    <div class="label">试验结果描述：</div>
                    <div class="info">{{ teamTestInfo.resultDesc }}</div>
                </div>

            </div>
            <div class="img-item">
                <div class="label">合格便签：</div>
                <div class="info" v-if="imgsList">
                    <div class="block" v-for="(item, index) in imgsList" :key="index">
                        <!-- <span class="demonstration">自定义</span> -->
                        <el-image :src="item" :preview-src-list="imgsList">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </div>

            </div>
        </el-card>

    </div>
</template>

<script>

import { queryTeamToolTest,getShTypes } from '@/api/teamTest.js'
export default {
    props: {
        id: {
            type: [String, Number]
        },
        tid: {
            type: [String, Number]
        }
    },
    data() {
        return {
            shTypeList: [],
            teamTestInfo: {
                id: null,
                tid: this.tid,
                shType: null,
                location: null,
                tester: null,
                testDate: null,
                isRegular: null,
                regularNote: null,
                labReportName: null,
                labReport: null,
                resultDesc: null
            },

            imgsList: [],
            path: process.env.VUE_APP_URL + this.UploadPath

        }
    },
    mounted() {
        this.loadShTypes()
        console.log(this.id)
        this.id && this.loadTeamTestInfo()
    },
    methods: {

        async loadShTypes() {
            await getShTypes().then(res => {
                if (res.code === '000000') {
                    this.shTypeList = res.data
                }
            })
        },
        //根据id查询设备信息
        async loadTeamTestInfo() {
            await queryTeamToolTest(this.id).then(res => {
                if (res.code === '000000') {
                    this.teamTestInfo = res.t
                    this.teamTestInfo.isRegular_text = this.teamTestInfo.isRegular === 0 ? '合格' : '不合格'
                    if (this.teamTestInfo.regularNote != null && this.teamTestInfo.regularNote != '') {
                        const list1 = this.teamTestInfo.regularNote.split(";")
                        list1.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = this.path + item
                                this.imgsList.push(obj)
                            }
                        })
                    }

                    this.shTypeList.forEach(item =>{
                        if(item.pKey===this.teamTestInfo.shType){
                            this.teamTestInfo.shTypeName = item.pValue
                        }
                    })
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                // width: 100%;
                float: left;
            }
        }
    }

    .img-item {
        width: 100%;

        .label {
            float: left;
            width: 80px;
            font-size: 14px;
            color: #4e5969;
        }

        .info {
            width: 180px;
            height: 90px;
            margin-bottom: 50px;
            float: left;
            .el-image {
                width: 100%;
                height: 100%;
            }
        }


    }



}
</style>